import "element-plus/lib/components/message-box/style/css";
import _ElMessageBox from "element-plus/lib/components/message-box";
import "core-js/modules/es.array.concat.js";
import BackgroundVideo from '@/components/backgroundVideo.vue';
import industryInstructions from '@/components/industryInstructions.vue';
import industryHighlightText from '@/components/industryHighlightText.vue'; // import mynFeatures from '@/components/mynFeatures.vue';

import '@/components/industry.css';
export default {
  name: 'Manufacturing',
  data: function data() {
    return {
      prestring: '<div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> 👷</small></div> <div class="more-info"> <h1>Advantages</h1> <div class="coords">',
      poststring: '<span class="more">Mouse over the card for more info</span> </div> </div> </div>'
    };
  },
  components: {
    BackgroundVideo: BackgroundVideo,
    industryInstructions: industryInstructions,
    industryHighlightText: industryHighlightText // mynFeatures,

  },
  methods: {
    wareHouse: function wareHouse() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card black\"> ".concat(this.prestring, " <span>Smart Warehousing: </span></div> <div class=\"coords\"> <span>E-ink Displays</span> <br> <span>MynOne Devices</span> <br> <span>Label tools</span> <br> <span>Real time view</span> </div> <!-- <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> --> </div> </div> <div class=\"general\"> <h1>Warehouse</h1> <p>Myneral can track goods within a warehouse in real time on the blockchain, and records are stored securely on the blockchain. </p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    rtTT: function rtTT() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card\"> ".concat(this.prestring, " <br> <img src=\"https://res.cloudinary.com/myn/image/upload/q_auto:eco/v1635767900/public-site/procurement_manager_d3udm7.webp\" width=\"250px\" /> </div> <!-- <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> --> </div> </div> <div class=\"general\"> <h1>Real Time Track&Trace</h1> <p>Real time track and trace allows you to view the lcoation of items as they are being transited in real time. This is achieved using the MynOne hardware with a combination of sensors. </p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    receivingSite: function receivingSite() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card blue\"> ".concat(this.prestring, "  <!-- <span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> --> </div> <!-- <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> --> </div> </div> <div class=\"general\"> <h1>Receiving Site</h1> <p>Integrated pick lists and order tracking make the receiving and sending of goods easier than ever before. The entire procurement process of goods can be recorded within myneral and can enable end-to-end tracking of goods.</p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    rtStorage: function rtStorage() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card purple\"> ".concat(this.prestring, " <!-- <span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> --> </div> <!-- <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> --> </div> </div> <div class=\"general\"> <h1>Real Time Storage</h1> <p>Myneral allows you to view the contents of diferent storage units in real itme. Whether on site or on the field, Myneral has all the answers within reach.</p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    container: function container() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card green\"> ".concat(this.prestring, " <!-- <span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> --> </div> <!-- <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> --> </div> </div> <div class=\"general\"> <h1>Container</h1> <p>.</p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    smokeStack: function smokeStack() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card\"> ".concat(this.prestring, " <!-- <span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> --> </div> <!-- <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> --> </div> </div> <div class=\"general\"> <h1>Smoke Stack</h1> <p>Myneral allows you to easily track losses within the manufacturing process on the blockchain. Through connecting with manufacturing hardware we can seamlessly track goods as they move throughout the supply chain.</p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    rPlan: function rPlan() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card blue\"> ".concat(this.prestring, " <!-- <span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> --> </div> <!-- <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> --> </div> </div> <div class=\"general\"> <h1>RPlan</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    dModel: function dModel() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card black\"> ".concat(this.prestring, " <!-- <span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> --> </div> <!-- <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> --> </div> </div> <div class=\"general\"> <h1>DModel</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    }
  }
};